import React,{Component} from 'react'
import './Lesson.css'
import {Table} from 'react-bootstrap'
import {LessonModal} from './LessonModal'
import {OnAdd, OnCRUD} from '../Shared/ActionControl'
import {ToDate,ToClick} from '../Shared/ViewControl'

export class Lessons extends Component{

    constructor (props){
        super(props)
        this.state = {items:[], itemId:0, modalShow:false, mode:0, loading:false}
        this.entity='Lesson'
    }

    componentDidMount(){
        this.Load()
    }

    Load() {
        this.setState({loading:true})
        this.props.global.GET(this.entity)
        .then(data => this.setState({items:data, loading:false}))
        .catch(error => console.log({ error }))
    }

    Delete(id) {
        this.props.global.DELETE(this.entity, id)
        .then(()=> this.Load())
    }

    componentWillUnmount() {
        this.setState({items:[]})        
    }

    render() {

        const {items}=this.state

        let filteredItems = items.filter(
            (item)=>{
                return (item.Name+item.Description).indexOf(this.props.search)!==-1;
            }   
        )

        let modalClose =() => {
            this.setState({modalShow:false})
            this.componentDidMount()
        }

        return (
            <div className='FullScreen'>

<Table striped hover bordered size="sm">
<thead>
    <tr>
        <th>رقم</th>
        <th>الدرس</th>
        <th>المعلمة</th>
        <th>ملاحظات</th>        
        <th>تاريخ</th>
        <OnAdd onClick={()=>this.setState({modalShow:true, itemId:0, mode:2})}/>
    </tr>
</thead>

<tbody>
    {
        filteredItems.map(item=>
            <tr key={item.Id}>

            <td>{item.Id}</td>
            <ToClick value={item.Id} text={item.Name} onClick={()=> this.setState({modalShow:true, itemId:item.Id, mode:0})} />
            <td>{item.Master}</td>
            <td>{item.Description}</td>
            <ToDate value={item.createdAt} />
            <OnCRUD 
                onInfo={()=> this.setState({modalShow:true, itemId:item.Id, mode:0})} 
                onEdit={()=> this.setState({modalShow:true, itemId:item.Id, mode:1})} 
                onDel={()=> this.Delete(item.Id)} 
            />
            </tr>
        )
    }
</tbody>
</Table>


{this.state.modalShow ?
    <LessonModal 
        global={this.props.global}
        entity={this.entity}
        id={this.state.itemId}
        mode={this.state.mode}
        show={true}
        onHide={modalClose}
    />
: null}

</div>
        )
    }
}