
const Hello = ({role, label}) => {
    
    //alert(role)
    const roles = '23'
    const salaam = './images/salaam.png'
    return (            
        roles.includes(role.toString()) 
        ?
        <div className='Home' style={{margin:'5rem 1rem'}}>
        <img src={salaam} alt={label} style={{margin:'5rem auto'}} />
        </div>
        :   null
    )
}


export {Hello }


/*
<div className='Home' style={{margin:'5rem 1rem'}}>
    <img src="./images/salaam.png" alt={iHome.title} style={{margin:'5rem auto'}} />
    </div>

 */