import React,{useState, useEffect} from 'react'
import './Planning.css'
import {Table} from 'react-bootstrap'
import {PlanModal} from './PlanModal'
import {PerformingModal} from '../Performing/PerformingModal'
import {OnAction, OnCRUD} from '../Shared/ActionControl'
import {ToDate,ToId} from '../Shared/ViewControl'
import {ToNotify} from '../Shared/ViewControl'
import { toTime, viewDate, Duration } from '../Shared/Formating'

export const PlanningTable = ({global, onPlan, classe, mode=0}) =>{

    const entity='Planning'
    const [loaded, Load] = useState(false)
    const [items , Items] = useState([])
    const [notify, Notify] = useState({open:false, message:''})
    const [action, Action] = useState({id:0, crud:0, show:false})

    useEffect(() => {
        Items([])
        let request = entity
        if(classe && classe>0)
            request=`${entity}/Detail/${classe}`

             global.GET(request)
             .then(data => {
                 Items(data)
                 Load(true)

                 if(action?.id>0) 
                    onSelect(action.id)
                 else if(data?.length>0) {
                    onSelect(data[0].Id)
                 }
             })
             .catch(error => {
                 Notify({open:true,message:error})   
             })
     }, [loaded, classe])


    const onSelect =(id) => {
        //alert(id)
        Action({...action, id:id})
        onPlan && onPlan(id)
    }

    const onClose =() => {
        Action({show:false, id:0, crud:0})
    }

    let filteredItems = items.filter(
        (item)=>{
            return (item.Course+item.Lesson+item.Team+item.Description).indexOf(global.search)!==-1;
        }   
    )

    return (


<div className='FullScreen'>

<ToNotify
message={notify.message}
onClose={()=>Notify({open:false, message:''})}
/>      

{

!loaded 
? 
<div>Planning is loading...</div> 
:
<Table striped hover bordered size="sm" >
<thead>
    <tr>
        <th>رقم</th>
        <th>معلمة</th>   
        <th>مجموعة</th>                  
        <th>دورة</th>
        <th>درس</th>    
        <th>يوم</th>
        <th>ساعة</th>
        <th>مدة</th>
        <th>ملاحظات</th>
        <th>تاريخ</th>
        <th>
        <OnAction rstyle={{justifyContent: 'center'}}
            onCreate={ (mode===1 || mode===9) ? ()=> Action({show:true, id:0, crud:1}) : null} 
            onRefresh={()=>Load(false)}
            />
        </th>        
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }}>
    {
        filteredItems.map(item=>
            <tr key={`${entity}-${item.Id}`}>


<ToId value={item.Id} onClick={()=>onSelect(item.Id)} selelcetd={action.id} />
<td>{item.Master}</td>
<td>{item.Team}</td>
<td>{item.Course}</td>
<td>{item.Lesson}</td>
<ToDate value={item.Date} />
<td>{toTime(item.Date)}</td>
<td>{Duration(item.Duration)}</td>
<td>{item.Description}</td>
<td>{viewDate(item.updatedAt)}</td>
<OnCRUD 
    onEdit={()=> {
        Action({show:true, id:item.Id, crud:3})   
        }} 
    onInfo={()=> Action({show:true, id:item.Id, crud:2})} 
    onDel={()=> Action({show:true, id:item.Id, crud:4})} 

    onAdd={ mode===9 ? ()=> Action({show:true, id:0, crud:9}) : null } 
/>
            </tr>
            )
    }
</tbody>
</Table>

}

{action.show ?
    action.crud===9 ?
        <PerformingModal
            global={global}
            planning={action.id}
            action={{id:0, crud:1, show:true}}
            onHide={onClose}
            />
        :
        <PlanModal
        global={global}
        action={action}
        onHide={onClose}
        />

: null}

</div>

        )
}