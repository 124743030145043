import React, { useState, useEffect } from 'react'
import {Row} from 'react-bootstrap'
import './Managing.css'
import SplitPane from 'react-split-pane'
import {Notes} from '../Note/Notes'
import { Timers, Focused } from '../Timer/Timers'
import {PerformingDetail} from '../Performing/PerformingDetail'
import {PlanningTable} from '../Planning/PlanningTable'


export const Managing = ({global, layout, search}) => {
        const activity=5
        const [planning, Plan] = useState(0)
        const [perform, Perform] = useState(0)
        const [reported, Reported] = useState(0)
        const [note, Note] = useState(0)
              
        Focused(1003, activity, Reported )

        const onPlan = (id) => {
                Plan(id)
        }

        const onPerform = (id) => {
                Perform(id)
        }

        const onNote = (id) => {
                Note(id)
        }

        useEffect(() => {
                sessionStorage.setItem("routeCurrent","/managing")
                //alert('useEffect '+itemId)
        }, [planning])    


return (

<>
                
{ global.connected() ?

<>
{ layout ?
<SplitPane split="vertical" defaultSize={"30%"} style={{height:'calc((100vh - 4.5rem) - 20px)'}}>

        <SplitPane split="horizontal" defaultSize={"70%"}>
                <Notes activity={activity} search={search} element={1003} onNote={onNote} />
                <Timers element={1003} activity={5} reported={reported}/>
        </SplitPane>

        <SplitPane split="horizontal" defaultSize={"40%"} >
                <PlanningTable
                        global={global}
                        onPlan={onPlan}
                        mode={9}  // mode for add performance from planning !!!
                        />

                <PerformingDetail
                        global={global}
                        onPerform={onPerform}
                        planning={planning}
                        />                
        </SplitPane>
   
</SplitPane>
:
<SplitPane split="horizontal" defaultSize={"40%"} >
<PlanningTable
                        global={global}
                        onPlan={onPlan}
                        mode={9}  // mode for add performance from planning !!!
                        />

                <PerformingDetail
                        global={global}
                        planning={planning}
                        />                    
</SplitPane>

}        
</>
: <h5>Not Connected</h5> }
</>
        )
}
