import React,{Component} from 'react';
import {Table} from 'react-bootstrap';
import {Button, ButtonToolbar,Container,Row,FormControl} from 'react-bootstrap';
import {ActivityEditModal} from './ActivityEditModal';

export class Activity extends Component{

    constructor (props){
        super(props)
        this.state = {items:[], modalShow:false, loading:false, itemId:0, mode:0}
        this.entity='Activity'
    }

    componentDidMount(){
            this.Load()
    }

    Load() {
        this.setState({loading:true})
        this.props.global.GET(this.entity)
        .then(data => this.setState({items:data, loading:false}))
        .catch(error => console.log({ error }))
    }    

    componentDidUpdate(prevProps, prevState){
        if (prevProps.items !== this.props.items) {
            this.Load();
        }
    } 
    
    Delete(id)
    {
        if(window.confirm('Are you sure ?')){
            fetch(this.props.apiUrl+'Activity/'+id,{
                method:'DELETE',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json',
                    Authorization: `Bearer ${this.state.store.token}`
                }
            })
            .then(response => {
                this.refreshList();
            }) 
            .catch(function (error) {
                console.log(error);
              });        
        }
    }
    
    render() {

        const {items}=this.state;

        let filteredItems = items.filter(
    
            (item)=>{
                return (item.Name+item.Phone+item.Country).indexOf(this.props.search)!==-1;
            }
            
        );

    let modalClose =() => {
        this.setState({modalShow:false})
        this.Load()
    }

    return (
            

<Row>
<div style={{overflow:"scroll", width:"96%", marginLeft:"2%"}}>
<Table striped hover bordered size="sm">
<thead>
    <tr>
        <th>رقم</th>
        <th>الإسم</th>
        <th>تقديم</th>
        <th>تاريخ</th>
        <th><ButtonToolbar style={{ display: 'flex', justifyContent: 'center' }}>
    <Button variant='primary' onClick={()=>this.setState({modalShow:true, itemId:0, mode:2})}>+</Button>

            </ButtonToolbar>
        </th>
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }}>
    {
        filteredItems.map(item=>
            <tr key={item.Id}>

<td>{item.Id}</td>
<td>{item.Name}</td>
<td>{item.Description}</td>
<td>{this.props.global.toString(item.Date)}</td>
<td>
    <ButtonToolbar style={{ display: 'flex', justifyContent: 'center' }}>
    <Button className="mr-2" variant="info"
        onClick={()=> this.setState({modalShow:true, itemId:item.Id})}
        >=</Button>

        <Button className="mr-2" variant="success"
        onClick={()=> this.setState({modalShow:true, itemId:item.Id, mode:1})}
        >*</Button>

        <Button className="mr-2" variant="danger"
        onClick={()=> this.Delete(item.Id)}
        >-</Button>

    </ButtonToolbar>
</td>
            </tr>
            )
    }
</tbody>
</Table>
</div>
{this.state.modalShow ?
        <ActivityEditModal
        global={this.props.global}
        itemId={this.state.itemId}
        mode={this.state.mode}
        show={true}
        onHide={modalClose}
        />
: null}
</Row>

        )
    }
}

/*

<Row>
    <ToTable fields={["رقم","الإسم","تقديم","تاريخ"]} items={items} 
    onAdd={()=>this.setState({modalShow:true, itemId:0, mode:2})}
    onEdit={()=>this.setState({modalShow:true, itemId:1, mode:1})}
    onInfo={()=>this.setState({modalShow:true, itemId:1, mode:0})} 
    />
</Row>

*/