import React,{Component} from 'react'
import {Modal, Row, Col,Form, Collapse} from 'react-bootstrap'
import {useHistory } from 'react-router-dom'
import {CountryControl} from '../Shared/CountryControl'
import {ToNotify, Loading} from '../Shared/ViewControl'
import {SpecialInfos} from '../Sign/SignControl'
import {InText, InDetail, InOption} from '../Shared/InputControl'
import {OnSubmit, OnHome} from '../Shared/ActionControl'
import { Service } from '../Service/Services'
import Api from '../Api'
import {sign as iSign} from '../Config/ar'

export class SignUp extends Component{

    constructor(props){
        super(props)

        this.state = {status:'', code:'', elapsed:0, confirmed:'', message:'', notify:'', selected:null
        ,submiting:false, telegram:true, linked:false, optional:false, name:false, phone:false, group:false}

        this.onSubmit = this.onSubmit.bind(this)

        this.device = {code:'',country:'',phone:'' }
        this.logo = 'logo.png'
    }

    static device

    componentDidMount() {
        this.interval = setInterval(() => {

            if(this.state.code.length===6) {

                this.setState({ elapsed: this.state.elapsed+1 })

                let ss = (new Date()).getSeconds()
                if(ss%10===1) {
                    this.onCheckLinked()
                }
            }
        
            //this.setState({submiting:!this.state.submiting})  juste for testing 
        }, 1000)

        // MeR 240119 disable back button in react...
        window.dispatchEvent(new CustomEvent("navigationhandler"))
      }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    onLogin = () => {
        const history = useHistory()
        history.push('/signin')
    }

    onOpen = (event) => {
        this.setState({optional:!this.state.optional});
    }

    onCheckLinked = () => {        
        if(this.state.confirmed==='' ) {
            //console.log('linked.device: ',this.device)

            if(this.device.code>100000 && this.device.phone.length>=9 ) {
                Api.post('/setting/linked', this.device)
                .then(response=>{
                    console.log('linked.response',response.data)
                    if(response.data?.id>0) {
                        //alert(`meCountry=${this.device.country}`)
                        document.cookie = `meCountry=${this.device.country}`
                        document.cookie = `mePhone=${this.device.phone}`
                        this.setState({notify: response.data.message})

                        //this.props.route.current="/signin"  // MeR 240206 01:35 !
                        //this.onLogin()

                        this.setState({confirmed: response.data.message})
                    }
                })
                .catch(error => {
                    //console.log('linked.error', error) 
                    this.setState({message:'...'})        
                })
            }
        }
        else {

        }
    }

    onSubmit(event) {
        event.preventDefault()
        this.setState({submiting:true, message:''})
        let item = {
            app: Api.defaults.domain,
            name: this.state.selected.Value,
            country: event.target.CountryId.value,
            phone: event.target.Phone.value.trim(),
            email: event.target.Email.value,
            title: event.target.Title.value,
            infos: event.target.Infos.value,
            age: event.target.AgeId.value,
            gender: event.target.GenderId.value,
            schooling: event.target.SchoolingId.value,
            recitation: event.target.RecitationId.value,
            memorization: event.target.MemorizationId.value,
        }

        Api.post('/signup', item)
        .then(response=>{
          console.log('signup response:', response)
          this.setState({submiting:false, status:iSign.status.hello})
  
          if(response?.data?.message) {  
            //alert(response.data.message) 
            if(response.data.message.includes("Salaam")) {   

                this.setState({code:response.data.code})
                this.copyText(response.data.code)
                this.device = {                
                    phone:item.phone,
                    country:item.country,
                    code:response.data.code
                }
            }
            else {
                this.setState({message:response.data.message})
            }
          }
          else {
            console.log('signup response',response)
          }
        })
        .catch(error => {        
          //alert(error.message)
          this.setState({submiting:false, status:iSign.error.problem})

          if(error.message.includes('409')) {
            this.setState({confirmed: iSign.confirmed})
          }
          else {
            console.log('signup error:',error)
            let notify=error?.response?.data?.message
            this.setState({submiting:false, message:notify, notify:notify})
          }
      })
    }

    onChangePhone = (e) => {
        let phone=e.target.value
        this.setState({phone:false})

        if(phone.startsWith('0')) {
            const phoneRegex = /^\d+$/
            if (phone.length===10 && phoneRegex.test(phone)) {
                this.setState({phone:true, message:''})
            }
            else {
                this.setState({message:iSign.error.phone.regex})
            }
        }
        else {
            this.setState({message:iSign.error.phone.start})
        }
    }

    Selected = (item) => {
        let valid = (item?.Value.length>2)
        //alert(`${item.Value} - ${valid}`)
        
        this.setState({selected : item, name:valid, message: valid?'':iSign.error.name})
    }

    onChangeName = (e) => {
        let name=e.target?.value.trim()
        this.setState({name:false})
        if(name.length>0)
        {
            const arabicRegex = new RegExp("^[\u0621-\u064A]+ ?[\u0621-\u064A]+ ?[\u0621-\u064A]+$")
            if (name.length>2 && arabicRegex.test(name)) {
                this.setState({name:true, message:''})
            }
            else {
                this.setState({message:iSign.error.name})
            }
        }
    }

    isEmail = (e) => {
        let email = e.target.value
        //alert(`${e.target.id} - ${email}`)
      }

    onRadio = (e) => {
        this.setState({
          telegram: (e.target.value==='Yes')
        })
      }

    onOption = (e) => {
        e.preventDefault()
        //Item({...item, [e.target.name]: e.target.value})            
        //console.log('Modal onChange', e.target.name, e.target.value)
    }

    onNotify = (notify='') => {
        this.setState({notify: notify})
    }

    onConfirm = (group=false) => {
        //alert(group)
        this.setState({group: group})
    }

    copyText = (text) => {
        if(navigator.clipboard)
          navigator.clipboard.writeText(text)
      }

    render() {

        return (
    <div className="sign">


    <Loading etat='التسجيل قيد الإرسال'  loading={this.state.submiting} />


{ this.state.confirmed.includes('Salaam') || this.state.confirmed.includes('تم') ?   
<> 
<div className='Services' >
    <Service name="الدخول" 
    icon="./images/hifd.png" 
    to="/signin"
    style={{width:'15rem', height:'18rem'}}
    />
</div>
<div className='message'>{iSign.confirming}</div>
</>
:
            <Modal.Body>

{ this.props.global.connected() ?
<Col sm={9} >
    <h1>{this.state.confirmed} مرحبا</h1>
</Col>
:

 <>
     <h4 style={{ marginBottom:'1rem', textAlign:'center' }}>{iSign.title}</h4>
     <>

{ this.state.code.length===6 
?
<>
    <Service style={{width:'16rem',height:'26rem', padding:'0.2rem', margin:'0 auto'}} 
    icon="images/telegram.png" 
    info={iSign.telegram.risaala} 
    plan={this.state.code} 
    start={iSign.telegram.start}
    to="https://telegram.me/AkademyMaBot"
    />
    { this.state.elapsed>10 ?
    <p className='message'>{iSign.telegram.elapsed.replace("؟؟",this.state.elapsed)}</p>
    :null}
</>    
:    

<>

<div className={this.state.telegram?'BasicInfos sm-valid':'BasicInfos sm-invalid'}>
    <h4 className='sm-c9' style={{ display: 'flex', justifyContent: 'flex-end' }}>{iSign.telegram.verification}</h4>
    
    <Row style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', paddingRight:'1rem'}} >
        <label className='col-sm-5 text-right'>
        لا
        <input type="radio" value="No" checked={!this.state.telegram} onChange={this.onRadio} style={{marginLeft:'0.5rem'}} />
        </label>

        <label className='col-sm-5 text-right'>
        نعم 
        <input type="radio" value="Yes" checked={this.state.telegram} onChange={this.onRadio} style={{marginLeft:'0.5rem'}} />
        </label>
    </Row>
</div>

{ this.state.telegram ?
    <Form onSubmit={this.onSubmit}>

         <div className={ (this.state.name && this.state.phone) ? "BasicInfos sm-valid" : "BasicInfos sm-invalid"}>         
            
<h4 style={{textAlign:"right"}}>{(this.state.name && this.state.phone) ? this.state.confirmed : iSign.requirement.required}</h4>

            <InOption entity={'Candidat'} label={"الأسم الثلاثي (بالعربية)"}  Selected={this.Selected} valid={this.state.name}/> 

            <CountryControl apiUrl={this.props.global.apiUrl} label='البلد'/> 
            <InText name="Phone" value="0" required label="رقم الهاتف" onChange={this.onChangePhone} valid={this.state.phone}/>  

            <Collapse in={this.state.name && this.state.phone}>
                <div style={{padding:"1rem", margin:"1rem 0"}}>
                    <SpecialInfos onOption={this.onOption} onConfirm={this.onConfirm} Message={this.onNotify} />
                </div>
            </Collapse>

        <input type='button' onClick={this.onOpen} className='sm-c9' style={{width:'100%', border:'none'}} 
        value={this.state.name && this.state.phone ? iSign.requirement.optional : ""} 
        />

        <Collapse in={this.state.name && this.state.phone && this.state.optional}>
            <div style={{padding:"1rem"}}>
            <InText name="Email" type="email" label="بريد إلكتروني" onBlur={this.isEmail}/>
            <InText name="Title" label="لقب"/>
            <InDetail name="Infos" label={iSign.label.infos}/>
            </div>
        </Collapse>

       </div>


    { this.state.name && this.state.phone && this.state.group ?
    <OnSubmit label={iSign.label.submit} />
    :
    
    <div className='Home'>
        <div className='message'>{this.state.name ? null : iSign.error.name}</div>
        <div className='message'>{this.state.phone ? null : iSign.error.phone.regex}</div>
        <div className='message'>{this.state.group ? null : iSign.error.group}</div>
    </div>
    

    }

    </Form>
:
<div className='Home'>
<img src="./images/telegramGroup.png" alt={iSign.requirement.telegram} style={{maxWidth:'600px'}}/>
<h6 className='message'>{iSign.error.telegram}</h6>
</div>
}
</>
            }
     </>
 </>
 
    }
            </Modal.Body>
            }

     
    <Modal.Footer>
        <OnHome />
    </Modal.Footer>

    <ToNotify
    message={this.state.notify}
    onClose={()=>this.onNotify('')}
    />

            </div>
        )
    }
}

