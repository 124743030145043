import React,{useState, useEffect} from 'react'
import {Col, Row} from 'react-bootstrap'
import './Timers.css'
import { activityStyle, Duration } from '../Shared/Formating'
import {Clickable} from '../Shared/ViewControl'
import {Fetch} from '../Api'

const Timers = ({element, onElement, reported}) => {
    const entity='Timer'

    const [items, Items] = useState([]);
    const [selected, Select] = useState(element);
    const [loaded, Load] = useState(false);

    useEffect(() => {
        if(!loaded) {
            //console.log('get timer: ', `/${entity}/${element}`, reported)
            Fetch(`${entity}`, null, 2, Load, null, Items)
        }
        else {
            if(items && items.length>0){
                let id=items[0]?.Id ?? 0
                if(id>0) {
                    //console.log('reported: ', reported, `Focused`, items[0].Focused)
                    items[0].Focused = parseInt(items[0].Focused)+reported*19
                    Select(id)
                }
            }
        }
    }, [loaded, element, reported])

    const onClick = (id) => {
        Select(id)
    }
 
        return (
            
<Row className="timer-grid">

{ loaded ?
<React.Suspense fallback="Loading..."> 
<h6>{entity}(s)</h6>
    {    
        items.map(item=>
            <Timer key={`timer-${item.Id}`} item={item} selected={selected} onSelect={onClick} onElement={onElement} />
        )
    }
</React.Suspense>   
:
<h6 style={{color:'darkred'}}>{entity}(s) loading...</h6>
}
</Row>
        )
}


const Timer = ({item, selected, onSelect, onElement}) => {

    //console.log('<Timer...', item)
    let cstyle= {...activityStyle(item?.Activity)}

    useEffect(() => {

    }, [selected])

    const onClick = (item) => {
        //alert(item.Id)
        onSelect(item.Id)
    }

    return (
        <Col className="timer-cell" style={{...cstyle, border: (selected===item.Id ?'2px solid white':'')}} onClick={()=>onClick(item)}>
            <Row className="align-items-center">
            <Col sm={4} style={{fontSize: '1.5rem'}}>{Duration(item.Focused)}</Col>    
            <Col sm={4} >{item?.Activity}</Col>    
            <Col sm={4} ><Clickable value={item?.Element} text={item?.Element} onClick={onElement} /></Col>    
            </Row>
            <Row className="align-items-center" style={{fontSize: '0.8rem'}}>
            <Col sm={4} >{item.Date}</Col>
            <Col sm={4} >{item.StartDate}</Col>
            <Col sm={4} >{item.EndDate}</Col>
            </Row>
        </Col> 
    )

}

const Focused = (element, activity, onReported) => {
    const [duration, Duration] = useState(0)
    const [reported, Reported] = useState(3)

    useEffect(() => {
        //alert(element)
            let number=19
            const timer = setTimeout(function() {
                //console.log("duration reported : ", duration, reported)   
                Duration(duration + 1)
                if(reported===duration)
                {
                    Fetch(`Timer`, {
                        ActivityId:activity,
                        ElementId:element,
                        Focused:duration*number
                    },1)
                    Duration(0)
                    let random=Math.floor(Math.random() * 3)+1
                    Reported(random)
                    onReported && onReported(random)
                    //alert(random)
                }
            }, number*1000)
        
            return () => { // this should work flawlessly besides some milliseconds lost here and there 
               clearTimeout(timer)
            }            
    }, [duration]);

}

export {Timers, Focused } 

const Tracking = (element, activity, onReported) => {

    var duration = Math.floor(Math.random() * 30)

    useEffect(() => {

        const onTimer = () => {
          
          Fetch(`Timer`, {
                ActivityId:activity,
                ElementId:element,
                Focused:duration
            }
            ,1)

            onReported && onReported(duration)
            duration = Math.floor(Math.random() * 30)
        };
    
        const interval = setInterval(onTimer, 1000*duration);    
        // Clean up the interval when the component unmounts
        return () => {
          clearInterval(interval);
        };
      }, [])    
}
