import React, {useState, useEffect} from 'react'
import {Button, Table} from 'react-bootstrap'
import {OnAdd, OnCRUD} from '../Shared/ActionControl'


const Loading = ({etat='', loading=false}) => {
    
    const file = 'https://akademy.ma/images/loading.gif'

    return (
        loading ?
        <div className='Loading'>
            <img src={file} alt={etat} style={{borderRadius: '50%'}} />
            <p>{etat}</p>
        </div>
        : null
    )
}

const ToDate = ({value}) => {
    let date = new Date(value)
    let text = date ? `${date.getYear()-100}-${date.getMonth()+1}-${date.getDate()}` : ""

    return (
        <td value={value} style={{ width:"6rem" }}>
            {text}
        </td>
    )
}

const ToId = ({value, onClick, crud=0, selelcetd=0}) => {  
    const bstyle = { width: '2rem', display: 'block', padding:'0.2rem', textAlign:'right' }  
    
    let bvariant = crud>0 ?  (crud===2 ? "outline-info" : "outline-warning") : "outline-secondary"
    if(selelcetd>0 && selelcetd===value)
        bvariant = bvariant.replace("outline-","")

    return (
<td key={value} value={value}>
    <Button value={value} variant={bvariant} style={bstyle}
        onClick={onClick}
    >{value}</Button>      
</td>
 )
}

const ToClick = ({value, text, onClick}) => {    
    return (
<td key={value} value={value}>
    <Clickable {...{value,text,onClick}} />
</td>
 )
}

const Clickable = ({value, text, onClick}) => {
    return (
        <Button value={value} variant="outline-info" style={{ maxWidth:"100%", textAlign:"right" }}
            onClick={onClick}
        >{text}</Button>      
    )        
}

const ToTable = ({fields, items, onInfo, onEdit, onDel, onAdd}) => {
    
    return (
        <div style={{overflow:"scroll", width:"97%", margin:"1%", direction:"rtl", textAlign:"right"}}>
<Table striped hover bordered size="sm">
<thead>
    <tr>
        {
            fields.map(field=>
                <th>{field}</th>
        )}
        <OnAdd onClick={onAdd}/>
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }}>
    {
        items.map(item=>
            <tr key={item.Id}>

<ToId value={item.Id} onClick={onInfo} crud='2' />

{ item.map(value =>  <td>{value}</td>) }

<ToDate value={item.updatedAt} />

<OnCRUD 
    onEdit={onEdit} 
    onDel={onDel}
/>
            </tr>
            )
    }
</tbody>
</Table>
</div>
 )
}

const ToNotify = ({message,onClose,onEtat=null}) => {
    let open = message && message.length>0

    if(open) {
        onEtat && onEtat(message)
    }

    return (

    open ? 
        <NotifyControl 
        open={open}
        message={message}
        onClose={onClose}
        /> 
    : null 

    )
}

export {ToNotify, ToDate, ToId, ToTable, ToClick, Clickable, Loading}

// expected one of "default","inherit","primary","secondary"
const NotifyControl = ({open,message,onClose}) => {
    const [timer, Timer] = useState(null)
    let color="default"
    let type=""
    let duration=3000
    if(message && message.length>1)
    {
        type= message.slice(message.length - 1)        
        switch(type) {
            case '!':
                color="teal"  //"success"
              break;
            case '?':
                duration=6000
                color="coral"
              break;
            case '.':
                color="gray"
              break;
            default: {
                if(message.includes('ضروري') || message.includes('؟')  || message.includes('?') ) {
                    duration=5000
                    color="orange"    
                }
                else {
                    color="default"
                }
            }
          }
    }

//  this it better    
    useEffect(() => {
        if(timer===null && message.length>0) {
            
            Timer(setTimeout(() => {
                onClose && onClose()
                console.log("setTimeout",duration)
            }, duration))
            
            return () => { clearTimeout(timer); Timer(null); }
        }
    }, [message])
    
/*
//  this it right 
    useEffect(() => {
        console.log("setTimeout",duration)
        const timer = setTimeout(() => {
            onClose && onClose()
          //console.log('This will run after 1 second!')
        }, duration);
        return () => clearTimeout(timer);
      }, []);
*/

    return (
        open ? 
        <Button key="NotifyControl"
             style={{
                backgroundColor: color, position:'fixed', bottom:'10%', left:'30%', width:'40%', height:'auto', zIndex:'9999', fontSize:'1.1rem'
              }}
              onClick={onClose}
        >
            {message}
        </Button>     
        : null   
    )
}
