import React, {useState, useEffect} from 'react';
import {Row, Col, Navbar} from 'react-bootstrap';
import {NavLink} from 'react-router-dom'; 
import {Navigation} from './Navigation';
import SignIn from './Sign/SignIn'
import SearchControl from './Shared/SearchControl'
import Config, {header as iHeader} from './Config/ar'

const Header = ({global, onSearch, role, onRole, route, layout}) => {
    const [showing, setShow] = useState(false)
    const [items , Items] = useState([])

    useEffect(() => {
        setShow(false)
        global.GET(`Setting/Authorization`)
        .then(data => {
            //console.log('Authorization', data, role)
            Items(data)
            if(data && data.length>0){
              //alert(data[0].RoleId)
              onRole(data[0].RoleId)
              //  console.log('Authorization', data, role)
            }
        })
        .catch(error => {
            console.log(error)
        })    

    }, [items])    

    const Logout = (e) => {
        localStorage.removeItem('Akademy')
        global.store=null
        window.location.reload()
    }

    const Login = (e) => {
        setShow(true)
    }

    return (
        
        <div className="Header" id="Header">
            <Row className="navbar navbar-custom" expand="lg">
            <Col sm={2} style={{top:"0"}}>
                <Navbar >
                { global.connected() ?
                <>
                    <NavLink className="navbar-custom" to="/" onClick={Logout} >
                        <i className="fa fa-sign-out">{iHeader.signout}</i></NavLink>
                    <SearchControl onSearch={onSearch} />
                </>
                :
                <>
                    <NavLink className="navbar-custom" style={{fontSize:'1.5rem'}} to="/signup" >
                        <i className="fa fa-edit">{iHeader.signup}</i></NavLink>  

                    <NavLink className="navbar-custom" style={{marginLeft:"2rem", fontSize:'1.5rem'}} to="/" onClick={Login} >
                        <i className="fa fa-sign-in">{iHeader.signin}</i></NavLink>  
                </>
                }
                </Navbar>
            </Col>             

            <Col sm={9}>
                { global.connected() 
                ?
                <Navigation items={items} role={role} route={route} />
                : 
                null }
            </Col>

            <Col sm={1} className="top-right">
                <a href="/">
                    <img className="Logo" src={Config.logo} alt={Config.home} />
                </a>
            </Col>            
            </Row>

{ showing ?
<SignIn 
    apiUrl={global.apiUrl}
    show={true}
    onHide={()=>{setShow(false)}}
    layout={layout}
/>
: null}                     
        </div>
        
        
    )
}

export default Header;