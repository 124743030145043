import React,{useState, useEffect} from 'react'
import './Performing.css'
import {Row} from 'react-bootstrap'
import SplitPane from 'react-split-pane'
import {PerformingDetail} from './PerformingDetail'
import {PlanningTable} from '../Planning/PlanningTable'

export const Performing = ({global,memberId,teamId,planningId}) => {
    const [itemId, setItemId] = useState(0)

    const onSelect = (event) => {
        //alert(event.target.value)
        //console.log("Performing.onSelect event.target", event.target)

        setItemId(event.target.value)        
    }

    useEffect(() => {
        //alert('Performing.useEffect itemId= '+itemId)
    }, [itemId])  

    return (

            <Row>
                
{ global.connected() 
?
    <SplitPane split="horizontal" defaultSize={"40%"} style={{ position:"relative"}}>
        <div>
        <PlanningTable
                global={global}
                onSelect={onSelect}
                mode={9}  // mode for add performance from planning !!!
                />

        </div>

        <div>
        <PerformingDetail
                global={global}
                parentId={itemId}
                />
        </div>
    </SplitPane>
: 
<h1>{global.message}</h1> }
</Row>
        )
    
}