import React,{Component} from 'react';
import { Form } from 'react-bootstrap';

export class RoleControl extends Component{
    constructor(props){
        super(props)
        this.state = {items:[], loading:false, role:1}

        this.itemId=this.props.id ?? 1
        this.label=this.props.label ?? 'Role' 
    } 

    componentDidMount(){
        this.setState({loading:true})
        let request = `${this.props.global.apiUrl}/Role`
        //alert(request)
        fetch(request, {
            method: "GET",
            headers: this.props.global.headers
        })
        .then(response => response.json())
        .then(data => {
            this.setState({items:data, loading:false})
        } )
        .catch(function (error) {
            console.log(error)
        })
    }

    onChange(event) {
        if(event?.target)
            this.setState({role:event.target.value})
    }

    render() {
 
        return (
            this.state.loading ? <div>Roles are loading...</div> :
            <Form.Group style={{marginTop:'1rem'}} >
                
<Form.Label style={{ display: 'flex', justifyContent: 'flex-end' }}>{this.label}</Form.Label>
            <Form.Control as="select" name="RoleId" defaultValue={this.itemId} readOnly={this.props.mode===0}>
                {this.state.items.map(role => 
                    <option key={role.Id} value={role.Id}>{(role?.Value ?? role.Name)}</option>
                )}
            </Form.Control>
            
         </Form.Group> 
            
            
        )
    }
    componentWillUnmount() {
        this.setState({items:[]})        
    }
}

