import React,{Component} from 'react'
import {Modal,Button,Row,Col,Form} from 'react-bootstrap'
import {MemberForm} from './MemberForm'
import {ToNotify} from '../Shared/ViewControl'
import {SpecialInfos} from '../Sign/SignControl'

export class MemberModal extends Component {
    constructor(props){
        super(props)

        this.state = {states:[], snackbaropen:false, snackbarmsg:'', Item:null, loading:false}
        this.handleSubmit = this.handleSubmit.bind(this)

        this.mode = this.props.mode ?? 0
        this.entity = "Member"
        //  alert(this.entity)
    }

    componentDidMount(){

        if(this.props.show && this.props.id > 0) {
            this.setState({loading:true})
            this.props.global.GetItem(this.entity,this.props.id)
            .then((data) =>  {
                    this.setState({Item:data, loading:false})
                }
            )
            .catch(error => console.log({ error }))
        }
        else if(this.props.mode===1) {
            this.setState({Item:{Id:0, Name:'Ism', CountryId:1, RoleId:1, Phone:'0000000000'}})
        }
    }

    snackbarClose = (event) => {
        this.setState({snackbaropen:false});
    }

    handleSubmit(event){
        event.preventDefault();

        let item = JSON.stringify({
            Id:this.state.Item.Id,
            CountryId:event.target.CountryId.value,
            Name:event.target.Name.value,
            Phone:event.target.Phone.value,
            Infos:event.target.Infos.value,
            Email:event.target.Email.value,
            Title:event.target.Title.value,
            Birthed:event.target.Birthed.value,
            Enrollmented:event.target.Enrollmented.value
            })

        fetch(`${this.props.global.apiUrl+'/'+this.entity}`,{
method:(this.mode===1)?'POST':(this.mode===3)?'PUT':'GET',
headers:this.props.global.headers,
body: item
})
.then(response => response.json())
        .then(
            (result)=>{
                this.setState({snackbaropen:true, snackbarmsg:result});

            },
            (error)=>{
                this.setState({snackbaropen:true, snackbarmsg:error});
            }
    )

}
    
    render() {
        return(
            <div className="container">
<ToNotify 
message={this.state.snackbarmsg}
onClose={this.snackbarClose}
/>

            <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {
                  this.mode===1 ?<h3>تحديث الطالبة</h3>:<h3>الطالبة</h3>
              }
              ({this.state.Item ? this.state.Item.Id : 0})
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

 <Row>
 {
    this.state.loading  || !this.state.Item ? <div>Member is loading...</div> 
    :
<>
<Col sm={6} >
    <SpecialInfos id={this.state.Item.Id} readOnly={this.mode===0} />
</Col>

<Col sm={6} >
    <Form onSubmit={this.handleSubmit} style={{width:'100%'}}>

             <MemberForm global={this.props.global} Member={this.state.Item} mode={this.mode} />

{this.mode>0 ?
            <Form.Group> 
                 <Button variant="success" type="submit">حفظ</Button>
            </Form.Group>
:null}

    </Form>
</Col>

</>        
    }
 </Row>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-danger" onClick={this.props.onHide}>إلغاء</Button>
            </Modal.Footer>
          </Modal>
          
          </div>  
        );
    }
}
