import React,{useState, useEffect} from 'react'
import {Row} from 'react-bootstrap'

import {InOption} from '../Shared/InputControl'
import {sign as iSign} from '../Config/ar'

import {Get} from '../Api'


const Confirming = ({member=0,id, onOption, onConfirm, Message, readOnly}) => {
    const [confirmed, Confirm]  = useState(false)
    const [selected, Selected]  = useState(null)

    const group = 'ما هي مجموعة الحفظ التي ترغبين الالحاق بها؟'
    const confirm = 'تأكيد مقدار الحفظ للانظمام للمجموعة'

    const onRadio = (e) => {
        Message()
        let group = (e.target.value==='Yes')

        Confirm(group)
        onConfirm(group)
        if(!group){
            Message(iSign.error.group)
        }
      }   

    return (
<div>
    <InOption member={member} entity={'Memorization'} id={id} onChange={onOption} label={group} readOnly={readOnly} Selected={Selected} valid={confirmed} /> 
    
    <h6 className={confirmed?'sm-c9':'message'} alt={confirm}>{selected?selected.Description:''}</h6>
    
    <Row style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', paddingRight:'1rem'}} >
        <label className='col-sm-5 text-right'>
        لا
        <input type="radio" value="No" checked={!confirmed} onChange={onRadio} style={{marginLeft:'0.5rem'}} />
        </label>

        <label className='col-sm-5 text-right'>
        نعم 
        <input type="radio" value="Yes" checked={confirmed} onChange={onRadio} style={{marginLeft:'0.5rem'}} />
        </label>
    </Row>
</div>
    )
}


const SpecialInfos = ({id=0, onOption=null, onConfirm, Message=null, readOnly=false}) => {
    const [loaded, Load] = useState(false)
    const [member, Member]  = useState({Age:39,Gender:41,Schooling:54,Recitation:53,Memorization:44, message:''})

    useEffect(() => {
        if(id>0 && !loaded) {
            let request =  `Member/Detail/${id}/Options`
            //console.log('SpecialInfos',request); alert(request)
            Get(request, Member, Load, Message)
        }
    }, [loaded])       


    const onSelect = (e) => {
        e.preventDefault()
        if(e.target.name==="GenderId") {      
            let sorry= (e.target.value==='4') ? '' : 'هذا البرنامج مخصص للنساء فقط'
      
            Member({...member, Gender:parseInt(e.target.value), message:sorry})
        }
        onOption && onOption(e)      
    }

    return (
<div>         
    <InOption member={id} entity={'Gender'} id={member.Gender} onChange={onSelect} label='الجنس' readOnly={readOnly}/> 

    {member.Gender===41 ? 
    <>
        <InOption member={id} entity={'Age'} id={member.Age} onChange={onSelect} label='السن' readOnly={readOnly} /> 
        <InOption member={id} entity={'Schooling'} id={member.Schooling} onChange={onSelect} label='مستوى التعليم' readOnly={readOnly}/> 
        <InOption member={id} entity={'Recitation'} id={member.Recitation} onChange={onSelect} label='الرواية' readOnly={readOnly}/>  

        <Confirming member={id} id={member.Memorization} onOption={onSelect} onConfirm={onConfirm} Message={Message} readOnly={readOnly} /> 
    </>
    :  
        <h6 className='message'>{member.message}</h6>
    }
</div>
    )
}

const Conditions = ({Message=null}) => {
    
    const choroot = 'شروط الالتحاق بمجموعات الحفظ بالسراج المنير'

    return (
        <div className='Home'>
            <h3>{choroot}</h3>
            <img src="./images/choroot.png" alt={choroot} />
        </div>
    )
}

export {Conditions, SpecialInfos}