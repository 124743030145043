import React, { useState, useEffect, useCallback } from 'react'
import '../Managing/Managing.css'
import SplitPane from 'react-split-pane'
import './Memorizing.css'
import {ToNotify} from '../Shared/ViewControl'
import MemorizeControl from './MemorizeControl'
import {Get} from '../Api'
import { Timers, Focused } from '../Timer/Timers'
import { Duration, dayName, toDate } from '../Shared/Formating'
import {Moshaf} from './Moshaf'
import {Notes} from '../Note/Notes'

const Memorizing = ({search, onEtat, role, course, layout}) => {
    const entity='Memorizing'
    const [page, Page] = useState(0)
    const [note, Note] = useState(0)
    const [reported, Reported] = useState(6)
    const [message, Message] = useState('')
    const [loaded, Load] = useState(false)
    const [items , Items] = useState([])
 
    const [item, Item]= useState({Element:1, Course:course, Focused:30, Date:new Date()})

    const [selected, Select] = useState(false)
    const [safahaat , Safahaat] = useState([])

    useEffect(() => {
        if(items.length===0) {
            Get(entity+"/Safha", Items, Load)
                        
            sessionStorage.setItem("routeCurrent","/memorizing")
        }
        else {
            let current=course>1?course:items[0].Course

            Item({...items[0], Course:current})

            Moshaf(`/waqafaat/Memorizing/${current}`, Safahaat, Select)

            //alert(current)  
        }    

    }, [loaded])


    useEffect(() => {
        //alert(`Course ${item.Course} Safahaat ${safahaat.length}`)
        if(safahaat.length>0) { 
            let i = safahaat.findIndex(s=>s.Safha===item.Element)
            let value = (i>0) ? item.Element : safahaat[0].Safha

            //alert(`Course ${item.Course} Page ${value}`)
            Page(value)

            onEtat(`${dayName(item.Date)} ${toDate(item.Date,false)} - ${item.Element} - ${Duration(item.Focused)} - صفحة ${page}`)
        }
    }, [selected])


    const onNote = (id) => {
        Note(id)
    }

    Focused(page, 4, Reported )
        
    const onPrev = () => {
        if(safahaat.length>1) {
            let p = safahaat.findIndex(s=>s.Safha===page)
            Page(p>0 ? safahaat[p-1].Safha : page)
        }
    }

    const onNext =() => {
        if(safahaat.length>1) {
            let n = safahaat.length-1
            let p = safahaat.findIndex(s=>s.Safha===page)
            //console.log('onNext...', page, safahaat, p, n)
            Page(p<n ? safahaat[p+1].Safha : page)
        }
    }

    const onPage = (event) => {
        let safha = event.target.value
        //console.log('Memorizing.onPage', event); alert(safha)

        Page(safha)
    }


    useEffect(() => {
        var simage=document.getElementById("image").style
        if(layout) {
            simage.maxHeight = "calc((100vh - 7rem) - 20px)"
        }
        else {
            simage.maxHeight = "calc((100vh - 6rem) - 20px)"
        }
    }, [layout])
                    

return (
                
<>

{ layout && page>0 ?
<SplitPane split="vertical" defaultSize={"70%"} >

    <SplitPane split="vertical" defaultSize={"30%"} >
            <Timers element={page} onElement={onPage} reported={reported}/>
            <MemorizeControl role={role} page={page} onNext={onNext} onPrev={onPrev} onPage={onPage} pages={safahaat} Message={Message} />
    </SplitPane>

    <Notes activity={4} element={page} search={search} onNote={onNote} />
</SplitPane>
: 
<MemorizeControl role={role} page={page} onNext={onNext} onPrev={onPrev} onPage={onPage} pages={safahaat} Message={Message} />
}

<ToNotify message={message} onClose={()=>Message('')} />
</>
     )
}

export default Memorizing
