import React,{Component} from 'react';
import { Form, ButtonToolbar, Button } from 'react-bootstrap';
import RecommendMember from './RecommendMember'

export class RoleMembers extends Component{
    constructor(props){
        super(props)
        this.state = {items:[], loading:false, modalShow:false, selected:this.props.id}

        this.apiUrl= `Setting/Role/${this.props.role}`
    }
 

    componentDidMount(){
        this.setState({loading:true})
        //alert(this.apiUrl)
        this.props.global.GET(this.apiUrl)
        .then(data => this.setState({items:data, loading:false}))
        .catch(error => console.log({ error }))
    }

    onSelect=(event) =>{
        if(event?.target) {
            this.setState({selected:event.target.value})
            //alert(event.target.value)
            this.props.onChange && this.props.onChange(event)
        }
        //console.log('OptionsControl onSelect',event.target.value)
    }

    onClose =(member) => {
        this.componentDidMount()
        //alert(member)
        this.setState({selected:member,modalShow:false})
        
    }

    render() {    
        return (            
            this.state.loading 
? 
    <div>{`${this.props.role} is loading...`}</div> 
:
<div>
    <Form.Group className='form-row' style={{ marginTop:'12px' }}>
        
            <ButtonToolbar className="col-sm-3" style={{ display: 'flex', justifyContent: 'center' }}>
                {this.props.readOnly ?
                null
                :
                <Button variant='primary' onClick={()=>this.setState({modalShow:!this.state.modalShow})}>{this.props.label} {`جديدة`} <i className="fa fa-plus"></i></Button>
                }
            </ButtonToolbar>
            
            <Form.Control as="select" name={this.props.role} className="col-sm-7 text-right" onChange={this.onSelect}
            defaultValue={this.state.selected} readOnly={this.props.readOnly} disabled={this.props.readOnly} >
                {this.state.items.map(member => 
                    <option  key={member.Id} value={member.Id}>{(member.Name+" "+member.Role)}</option>
                )}
            </Form.Control>
            
            <Form.Label className='col-sm-2' align='right'>:{this.props.label}</Form.Label> 

            
    </Form.Group> 

         {this.state.modalShow ?
                <RecommendMember 
                    entityId={this.props.entityId}
                    entity={this.props.entity}
                    role={this.props.role}
                    onHide={this.onClose}
                    Message={this.props.Message}
                />
                : null}
  
</div>
  
        ) 
    }

    componentWillUnmount() {
        this.setState({items:[]})        
    }
}