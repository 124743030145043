import React,{Component} from 'react';
import { Form } from 'react-bootstrap';

export class CountryControl extends Component{
    constructor(props){
        super(props)
        this.mounted = false
        
        this.state = {countries:[], loading:false, country:1, matches: window.matchMedia("(max-width: 768px)").matches}
        this.apiUrl=`${this.props.apiUrl}/Country`

        this.itemId=this.props.itemId ?? 1
        this.label=this.props.label ?? 'Country'
        this.readOnly = this.props.readOnly ?? false

        this.onCountry = this.props.onChange ?? this.onChange
        
    }
 

    componentDidMount(){
        this.mounted = true
        this.setState({loading:true})
        fetch(this.apiUrl)
        .then(response => response.json())
        .then(data => {
            this.setState({countries:data, loading:false})
        } )
        .catch(function (error) {
            console.log(error)
          })
    }

    componentWillUnmount() { 
        this.mounted = false   
        this.setState({countries:[]})     
    }

    onChange(event) {
        this.setState({country:event.target.value})
    }

    render() {
 
        return (
            this.state.loading ? 
            <h5>Countries are loading...</h5> 
            :
            <Form.Group className='form-row' style={{marginTop:'1rem',fontSize:'1.2rem', padding:'0 1rem', color:'black'}}>
                
            <Form.Control className='col-sm-12 text-right pr-2' onChange={this.onCountry} as="select" 
                name="CountryId" defaultValue={this.itemId} 
                style={{marginTop:'1rem'}}
                readOnly={this.readOnly} >
                {this.state.countries.map(country => 
                    <option style={{paddingRight:'1rem', textAlign:'right'}} key={country.Id} value={country.Id}>{(country.Name+" "+country.Phone)}</option>
                )}
            </Form.Control>
         </Form.Group>             
        ) 
    }
    
}
