import React, { useState, useEffect } from 'react'
import './Classe.css'
import {Table} from 'react-bootstrap'
import {ToNotify} from '../Shared/ViewControl'
import {OnAdd, OnCRUD} from '../Shared/ActionControl'
import {ToDate,ToId,ToClick} from '../Shared/ViewControl'
import ClasseModal from './ClasseModal' 

export const ClasseDetail = ({global, parent}) => {
    const entity='Classe'
    const [loaded, Load] = useState(false)
    const [items, Items] = useState([])
    const [message, Message] = useState('')
    const [action, Action] = useState({id:0, crud:0, show:false})

    useEffect(() => {
        let request = entity
        if(parent && parent>0)
            request=`Course/Detail/${parent}`
       
        global.GET(request)
        .then(data => {
            Items(data)
            Load(true) 
        })
        .catch(error => {
            console.log(error)
            Message(error)   
        })
        
    }, [loaded, parent])
 
        return (
  
<>

{ loaded ?
    <div className='FullScreen'>
<Table striped hover bordered size="sm">
<thead>
    <tr>
        <th>رقم</th>
        <th>الدورة</th>
        <th>الدرس</th>
        <th>المعلمة</th>
        <th>المفتشة</th>
        <th>تاريخ البدء</th>
        <th>الانتهاء</th>
        <th>ملاحظات</th>        
        <OnAdd onClick={()=>Action({show:true, id:0, crud:1})}/>
    </tr>
</thead>

<tbody style={{ justifyContent: 'flex-end' }}>
    {
        items.map(item=>
<tr key={item.Id}>

    <ToId value={item.Id} onClick={()=> Action({show:true, id:item.Id, crud:2})} />
    <td>{item.Course}</td>
    <ToClick value={item.Id} text={item.Lesson} onClick={()=> Action({show:true, id:item.Id, crud:2})} />
    <td>{item.Master}</td>
    <td>{item.Inspector}</td>
    <ToDate value={item.StartDate} />
    <ToDate value={item.EndDate} />
    <td>{item.Description}</td>

    <OnCRUD 
        onEdit={()=> Action({show:true, id:item.Id, crud:3})} 
        onDelete={()=> Action({show:true, id:item.Id, crud:4})} 
    />

</tr>
            )
    }
</tbody>
</Table>
{action.show ?
        <ClasseModal
        global={global}
        action={action}
        onHide={()=>Action({show:false, id:0, crud:0})}
        />
: null}

</div>
: <h6>{entity} is loading...</h6> }

<ToNotify message={message} onClose={()=>Message('')} />

</>
        )
}
