import axios from 'axios';

const waqafaatUrl = "https://moshaf.akademy.ma/waqafaat/"

const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiaXNtaSI6Itio2ZDYs9uh2YXZkCDZsdmE2YTZh9mQINmx2YTYsdmR2Y7Yrduh2YXZjtmA2bDZhtmQINmx2YTYsdmR2Y7YrdmQ2YrZhdmQIiwiYWxhYW4iOiIxMC8xMi8yMDIyIiwiaWF0IjoxNjY1NTc2NDI5fQ.lYIP0dvSfiTaoJ1r42i168YbeHl2VCw-ToHLYQ7AuSI";

const Api = axios.create({

  baseURL: `https://api.echchatt.net`,   

  headers : {
    'Authorization': `Bearer ${token}`,       
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const Moshaf = (request, Items, Load, Message) => {
  //alert(request)
  Items([])
  Api.get(request)
  .then(response => {
      //console.log('Moshaf.Get', request, response.data); alert('Moshaf.Load '+request)
      Items(response.data)
      Load && Load(true)
  })
  .catch(error => {
      //if (error.status && error.data)
      Message && Message(error)
      console.error(error)
  })
}

const onUpdate = (id, field, value, Message) => {

  if(id && id>0 && field && value) {
    let query=`/waqafaat/${id}/${field}/${value}`
    //alert(query)
    Api.put(query)
    .then(response => {
        console.log('Moshaf.onUpdate', response.status, response.data.affectedRows)
    })
    .catch(error => {
      Message && Message(error)
      console.error(error)
    })
  }
}


const getPage = (page) => {
  let name = (page||0).toString().padStart(3,'0')+'.jpg'
  return waqafaatUrl+name
}

const getPart = (item) => {
  if(item){
    let name = `${item.Soora.toString().padStart(3,'0')}/${item.Sah}.mp3`
    return waqafaatUrl+name
  }
}

export {Moshaf, onUpdate, getPage, getPart, waqafaatUrl}
