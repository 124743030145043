import React, { useState, useEffect } from 'react'
import './Booking.css'
import {Form,Row,Col} from 'react-bootstrap'

import {ToNotify} from '../Shared/ViewControl'
import {InDetail} from '../Shared/InputControl'
import {OnSubmit} from '../Shared/ActionControl'

import Api from '../Api';

function MembersList(items, color, onMember) {
    const [member, setMember] = useState(0);
    
    useEffect(()=>{
        onMember(member)
    },[member])

    const listItems = items.map((item) =>
            <li key={"M-"+item.Id} 
            value={item.Id} 
            title={item.Id+" "+item.Notes}
            style= {{ borderColor: (item.Id===member ? 'gold' : 'green'), backgroundColor:color }} 
            onClick={() => {
                (member===0 || member!==item.Id)?setMember(item.Id):setMember(0)
            }}
        >
            {item.Name}
        </li>
        )

        return (
            <ul style={{ backgroundColor:color }}>{listItems}</ul>
        )
}

function BookingList(items, color, onOrder, onNotes) {
    const [order, setOrder] = useState(0)
    const [notes, setNotes] = useState('')

    useEffect(()=>{
        onOrder(order)
        onNotes(notes)
    },[order, notes])

    const setItem = (item) => {
        if(item) {
            setOrder(item.Order) 
            setNotes(item.Notes)
        }
        else {
            setOrder(0)
            setNotes('')
        }
    }

        const listItems = items.map((item) =>
        <li key={item.Id} 
            value={item.Id} 
            title={item.Notes}
            style= {{ 
                backgroundColor:color, 
                borderColor: item.Order===order ? 'gold' : 'gray', 
                
                pointerEvents: (item.Name.length>0) ? 'none' : 'auto'
            }}
                

            onClick={() => {

                (order===item.Order) ? setItem() : setItem(item)

            }}
        >

            <h5>{item.Name} {item.Order}</h5>
        </li>
        );
        return (
            
        <ul style={{ backgroundColor:color }}>{listItems}</ul>


        )
}


export const BookingDetail = ({PlanningId}) => {
    const [order, setOrder] = useState(0)
    const [loaded, setLoad] = useState(false)
    const [now, setTime] = useState(new Date())
    const [data, setData] = useState({planned:[], booked:[]})
    const [member, setMember] = useState(0);
    const [notes, setNotes] = useState('')
    const [message, setMessage] = useState('')

    const postOrder = (event) => {
        event.preventDefault()
        setLoad(false)

        var responses = {
            PlanningId,
            MemberId:member,
            Order : order,
            Notes : event.target.Notes?.value,
            createdAt:now,
        }

        // alert('postAnswer', responses)

        Api.post('Booking', responses)
        .then(response => {
            //console.log('data',response.data)
            setTime(new Date())
            setLoad(true)
            setMessage(response)
        })
        .catch(error => {
            //if (error.status && error.data)
            setMessage(error)
            console.error(error)
        })
    }

    useEffect(() => {
        setOrder(0)
        setMember(0)
        setData({planned:[], booked:[]})
        setLoad(false)

        if(PlanningId>0) {
        //  alert(`Booking/${PlanningId}`)
        Api.get(`/Booking/${PlanningId}`)
            .then(response => {
                //console.log('Booking',response.data)
                setData(response.data)
                setLoad(true)
                setMessage(response)
            })
            .catch(error => {
                //if (error.status && error.data)
                setMessage(error)
                console.error(error)
            })
        }
    }, [PlanningId])
 
        return (
  
<>
<ToNotify
message={message}
onClose={()=>{setMessage('')}}
/>

{ loaded ?
    <Form onSubmit={postOrder} style={{width:'100%'}}> 
        <h3>Booking {PlanningId}</h3>    
        <Row>
            <Col>
                <MembersList items={data.planned} color={"gray"} onMember={setMember}/>
            </Col>
            <Col>

                <BookingList items={data.booked}  color={"green"} onOrder={setOrder} onNotes={setNotes} />            
            </Col>
        </Row>

        { member>0 ?
        <div>
            <InDetail name="Notes" value={notes} label={"ملاحظات"} size={"2rem"} />

            { order>0 ?
            <OnSubmit label="تأكيد" />
            : null }
        </div>

        : null }

        </Form> 
: <h6>Booking loading...</h6> }
</>
        )
}
