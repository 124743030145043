import React, { useState, useEffect, useCallback } from 'react'
import {Col, Row, Button, Collapse} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import './Course.css'
import {ToNotify} from '../Shared/ViewControl'
import {OnAction} from '../Shared/ActionControl'
import { viewDate, toDate, styleRegister } from '../Shared/Formating'
import CourseModal from './CourseModal' 
import {Get, Fetch} from '../Api'

const Courses = ({onCourse, global, search="", role}) => {
    const entity='Course'
    const [loaded, Load] = useState(false)
    const [items, Items] = useState([])
    const [message, Message] = useState('')
    const [action, Action] = useState({id:0, crud:0, show:false})
    const [fetched, Fetched] = useState(false)

    const [entities, Entities]= useState({Course:(role>3), Bloqued: false})

    const onCollapse = (e) => {
        Entities({...entities, [e.target.name]: !entities[e.target.name]})
    }

    useEffect(() => {

        if(!loaded) {
            Get(entity, Items, Load, Message)
            sessionStorage.setItem("routeCurrent","/course")
        }
        //console.log('Courses...', items)
    }, [])    


    let filteredItems = items.filter(
        (item)=>{
            return (item.Name+item.Description+item.Manager).indexOf(search)!==-1;
    })

    const onHide = () => {
        if(fetched) {
            Load(false)
            Fetched(false)
        }
        Action({...action,show:false, crud:0})
    }

    const onRegister = (item) => {
        if(item.Status===1) {
            let request=`${entity}/Register/${item.Id}`
            Fetch(request, null, 1, Load, Message)
        }
        else {
            console.log('onRegister ?', item.Status)   
        }
    }
    
    return (
<>

{ loaded ?
<>
    <div className="Elements">        
    {
        filteredItems.filter((item)=>{return (item.Status===3) }).map(item=>
            <Course key={`Course-${item.Id}`}
            item={item}
            icon="./images/hifd.png" 
            onAction={Action} 
            onCourse={onCourse}
            role={role}
        />)
    }

    { action.show ?
            <CourseModal
            global={global}
            action={action}
            onHide={onHide}
            Fetched={Fetched}
            Message={Message}
            />
    : null }
</div>

    <div style={{border:"12px solid green", borderRadius:"15px", justifyContent:"center",  margin:'3rem 5% 2% 5%', width:'90%'}}>         

        <Button name="Course" onClick={onCollapse} 
        style={{backgroundColor:"green", borderColor:"gold", textAlign:'right', width:'100%'}}>
        {"الدورات المتاحة"} 
        </Button>   
        <Collapse in={entities.Course}>
        <div className="Elements">
        {
        filteredItems.filter((item)=>{return (item.Status!==3)}).map(item=>
            <Course key={`Course-${item.Id}`}
            item={item}
            icon="./images/hifd.png" 
            onAction={Action} 
            onRegister={onRegister}
            onCourse={onCourse}
            role={role}
        />)
        }
        
        </div>
        </Collapse>
    </div> 
</>

: <h6>{entity} is loading...</h6> }

<ToNotify message={message} onClose={()=>Message('')} />

</>
        )
}


const Course = ({item, icon, onAction, onRegister, onCourse, role}) => {
    const history = useHistory();
    const onMemorizing = useCallback(() => history.push(`/memorizing`), [history])
    
    const onClick = (id) => {
        onCourse(id)
    }
  
    const cstyle={fontSize: '1rem', justifyContent:'center', alignItems:'center', color:'white' }

    let sitem = styleRegister(item)

    return (
        <div className='Element' key={`Course-${item.Id}`}>
            <Col style={{width: "calc(100% - 3px)"}} onClick={()=>onClick(item.Id)}>
                <div className="center">
                    <img src={icon} alt={item.Manager+":"+item.Name} />
                </div>

                <Row style={{...cstyle, fontWeight: 'bold', color:'yellow', marginTop:'1rem'}}>{item.Manager}</Row>
                <Row style={{...cstyle, fontWeight: 'bold', fontSize: '1.2rem', margin:'1rem 0'}}>{item.Name}</Row>
                <Row style={{...cstyle, color:'cyan'}}>{item.Level}</Row>

                <Row style={{...cstyle, fontSize: '0.9rem'}}>{item.Description}</Row>

                <Row style={{marginTop:'0.5rem'}}>
                <Col sm={7} style={{...cstyle, color: 'cyan'}}>{viewDate(item.StartDate, false)}</Col>
                <Col sm={5} style={{...cstyle, color: 'cyan'}}>{toDate(item.EndDate).substring(5)}</Col>
                </Row>
{ item?.Status===3 || role>6 ?
                <Row style={{margin:'1rem 0.5rem'}}>
                <Col sm={6} style={{...cstyle, color: 'gold', fontSize: '1.2rem'}} 
                    onClick={
                        onMemorizing
                    }>{"حفظ"}</Col>
                <Col sm={6} style={{...cstyle, color: 'gold', fontSize: '1.2rem'}} 
                    onClick={()=>onAction({id:item.Id, crud:2, show:true})}>{"تدبر"}</Col>
                </Row>
: null}
                
{ onRegister && item?.Role<3 ? 
    <div className='toRegister' style={sitem.style} onClick={()=>onRegister(item)}>{sitem.label}</div>
: null}   
            </Col>

            {item.Role>1? 
            <OnAction 
            onEdit={ ()=>onAction({id:item.Id, crud:3, show:true}) } 
            onExpand= { item.Role>5 ? ()=>onAction({id:item.Id, crud:2, show:true}) : null }
            />     
            : null}        
        </div>
    )


}

export {Courses}

/**
 * , border:'1px solid gold'
 * 
 */