import React,{useState, useEffect} from 'react';
import { Form } from 'react-bootstrap';
import {Get} from '../Api'



const MemberControl = ({member, label, role, onChange, Message, crud}) =>{

    const [loaded, Load] = useState(false)
    const [items , Items] = useState([])

    useEffect(() => {
        Items([])
        let request =  `Setting/Member/${role}`
        //console.log('Members.onLoad',request); alert(request)
        Get(request, Items, Load, Message)
     }, [loaded])   

    return (

        loaded ? 
        <Form.Group>
        <Form.Label style={{ display: 'flex', justifyContent: 'flex-end' }}>{label}</Form.Label>
        <Form.Control as="select" name="MemberId" onChange={onChange} defaultValue={member} readOnly={crud===2} disabled={crud===2}>
            {items.map(item => 
                <option key={item.Id} value={item.Id}>{(item.Name)}</option>
            )}
        </Form.Control>

        </Form.Group>    
        :  
        <div>Members are loading...</div>   
    )
}

export {MemberControl}

