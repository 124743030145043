import React, {useState, useCallback, useEffect} from 'react'
import {Services, Service} from './Service/Services'


import {Cookie} from "./Api"
import Config, {home as iHome, sign as iSign} from './Config/ar'
import {Hello} from './Page/Message' 


const Home = ({search, appLayout, connected=false, role=2}) => {
  const [layout, Layout] = useState(appLayout)

  const onResize = useCallback(event => {
    let value=window.matchMedia('(orientation:landscape)').matches    
    Layout(value)
  }, [])

  useEffect(() => {
          window.addEventListener("resize", onResize);
          return () => {
              window.removeEventListener("resize", onResize);
          };
  }, [onResize, layout])
    

  const meTitle=Cookie('meTitle')??Config.salaam


    return (
  
connected ?
    <>
      <div className='Home title'>{meTitle}</div>
      
      { role<=2 ?  // 2 just for starting

        <Hello label={iHome.title} role={role} />
      
      
      :
      < Services search={search} layout={layout} />
      }
    </>
:
<div className='Home'>
    <img src="./images/title.png" alt={iHome.title} style={{margin:'2rem 1rem'}} />
    <div className='Services' >
        <Service name={iSign.name} 
            icon="logo.png" 
            to="/signup"
            style={{width:'15rem', margin:'0 auto'}}
            info={iSign.info}
        />
    </div>
</div>
    )

    /*
<Service name="الدخول" 
  icon="./images/hifd.png" 
  to="/signin"
/>
 */

  }
  
  export default Home
  
