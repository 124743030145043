import React from 'react'
import {NavLink} from 'react-router-dom'
import {Navbar} from 'react-bootstrap'

export const Navigation = ({items, role, route}) =>{

        if(role>0) {

          switch(role) {
            case 1: 
            route.current="/"
            break
      
          case 2 : 
          case 3 : 
            route.current="/home"
            break

          case 4 : 
          case 5 : 
          case 6 : 
            route.current="/planning"
            break

          case 7:
              route.current="/setting"
            break

          case 8:    
            route.current="/planning"
            break

          case 9: 
            //route.current="/registering"
            route.current=sessionStorage.getItem("routeCurrent") ?? "/registering"
            break
      
          default:
            route.current = "/course"
            break
        }

        if(role>6){
          sessionStorage.setItem("routeCurrent", route.current);  
        }
        //  alert(`role:${role} route:${route.current}`)
      }
    

    const navItems = (
      role>1 ?
        <Navbar.Collapse id="basic-navbar-nav" >
          <NavLink className="navbar-custom" to="/course">الدورات</NavLink>
        
          {role>1 ?
          <>
          <NavLink className="navbar-custom" to="/memorizing">إحفظ</NavLink> 
          <NavLink className="navbar-custom" to="/performing">تنفيذ</NavLink>
          <NavLink className="navbar-custom" to="/booking">حجز المرور</NavLink>
          </>
          :null}

          {role>2 ? 
          <>
          <NavLink className="navbar-custom" to="/team">الفرق</NavLink>
          <NavLink className="navbar-custom" to="/subject">المواضيع</NavLink>
          <NavLink className="navbar-custom" to="/planning">تخطيط</NavLink>    
          </>
          :null}

          {role>6 ?
          <>
          <NavLink className="navbar-custom" to="/setting">إعدادات</NavLink>
          <NavLink className="navbar-custom" to="/person">الأعضاء</NavLink>
          <NavLink className="navbar-custom" to="/managing">إدارة</NavLink>
          <NavLink className="navbar-custom" to="/registering">مُسجَّلون</NavLink>
          </>
          :null}

        </Navbar.Collapse>
      : null 
    ) 


  return (
    role>2 ?
        <Navbar className="navbar-down sm-valid" expand="lg">
          <Navbar.Toggle className="navbar-dark bg-inverse" />
          {navItems}
        </Navbar> 
    :null
    )
}


const Dropdown = ({ submenus }) => {
    return (
      <ul className="dropdown">
        {submenus.map((submenu, index) => (
          <li key={index} className="menu-items">
            <a href={submenu.url}>{submenu.title}</a>
          </li>
        ))}
      </ul>
)}
