import React, {useState, useEffect} from 'react';
import {Col} from 'react-bootstrap';
import Config from './Config/ar'

const Footer = ({etat='',  layout}) => {

    const [time, Time] = useState("");
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }

    useEffect(() => {
        setTimeout(() => {
            Time((new Date()).toLocaleDateString('ar-MA', options))
            //console.log(time)
        }, 1000);
    },[time]);


    return (
        <div className="Footer" style={layout?{height: '1rem'}: {height: '1rem'}}>
            <Col sm={6} style={{left:"0"}}>
            <i className="FooterEtat">{etat?.length>0 ? etat : Config.title}</i>
            </Col>
            
            <Col sm={6} style={{right:"0"}}>
            <i>{time}</i>
            </Col>
        </div>
    )
}

export default Footer;

