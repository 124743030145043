import React,{useState,useEffect} from 'react';
import './Member.css';
import {Col, Container, Row, FormControl} from 'react-bootstrap';
import {MemberModal} from './MemberModal';
import {MemberTeams, MemberClasses} from './MemberDetail';
import {OnAction} from '../Shared/ActionControl'
import { viewDate, toDate } from '../Shared/Formating'
import {ToNotify} from '../Shared/ViewControl'

const MemberGrid = ({global, onMember}) =>{

    const entity='Member'
    const [loaded, Load] = useState(false)
    const [search, Search] = useState('')
    const [items , Items] = useState([])
    const [message, Message] = useState('')
    const [action, Action] = useState({id:0, crud:0, show:false})

    useEffect(() => {
        onLoad()
     }, [loaded])

    const onSelect =(id) => {
        Action({...action, id:id})
        onMember && onMember(id)
    }

    const onLoad = () => {
        //Message("Load .")
        global.GET(entity)
        .then(data => {
            Items(data)
            Load(true)
            //console.log('Members',data)
        })
        .catch(error => {
            Message(error)   
        })    
    }

    const onChange = (e) => {
        e.preventDefault()
        Search(e.target.value)
    }
    
    let filteredItems = items.filter(
        (item)=>{
            return (item.Name+item.Phone+item.Country+item.Infos+item?.Email).indexOf(search)!==-1;
        }
    )

    const onClose =() => {
        Action({show:false, id:0, crud:0})
        Load(false)
    }

        return (
            <>
<ToNotify message={message} onClose={()=>Message('')} />

<div className="Members">
{
        filteredItems.map(item=>
            <Member key={`Member-${item.Id}`}
            item={item}
            avatar={"./images/tajwyd.png"}
            onAction={Action} />
        )
}
</div>

{action.show ?
        <MemberModal
        global={global}
        id={action.id}
        mode={action.crud}
        show={true}
        onHide={onClose}

        />
: 
<>
{ action?.id > 0 ?
<>
    { action.crud===21 ?
    <MemberTeams global={global} member={action.id}  /> 
    : null } 

{ action.crud===22 ?
    <MemberClasses global={global} member={action.id}  /> 
    : null } 
</>
: 
null }
</>
}

</>
        )
}

const Member = ({item, avatar, onAction}) => {
    const cstyle={fontSize: '1rem', justifyContent:'center', alignItems:'center', fontWeight: 'bold', color:'blue' }
    const istyle={fontSize: '1rem', color: 'blue', fontWeight: 'bold', justifyContent:'center', textAlign:'center', border:'1px solid white', borderRadius:'12px', padding:'6px'}
    //alert(name)

    return (
        <div className='Member' >
            <Col>
            <div className="center">
                <img src={avatar} alt={item.Name+":"+item.Phone} />
            </div>
                <Row style={{...cstyle, color:'gray'}}>{item.Title}</Row>
                <Row style={{...cstyle, fontSize: '1.5rem'}} onClick={()=>onAction({id:item.Id, crud:0, show:false})}>{item.Name}</Row>
                <Row style={{marginTop:'0.5rem'}}>
                <Col sm={4} style={cstyle}>{item.Country}</Col>
                <Col sm={8} style={cstyle}>{item.Phone}</Col>
                </Row>
                <Row style={{...cstyle, color: 'black'}}>{item.Email}</Row>
                
                <Row style={{...cstyle, fontSize: '0.8rem', color: 'black', margin:'1rem 0 1rem 0'}}>{item.Infos}</Row>
                

                <Row>
                <Col sm={8} style={{...cstyle, color: 'gray'}}>{toDate(item.Enrollmented).substring(2)}</Col>
                <Col sm={4} style={{...cstyle, color: 'gray'}}>{item.Role}</Col>
                </Row>
                <Row style={{margin:'1rem 0 1rem 0'}}>
                <Col sm={6} style={istyle} onClick={()=>onAction({id:item.Id, crud:22, show:false})}>{item.classes} قسم</Col>
                <Col sm={6} style={istyle} onClick={()=>onAction({id:item.Id, crud:21, show:false})}>{item.teams} فريق</Col>
                </Row>
            </Col>
            <OnAction 
            onEdit={ ()=>onAction({id:item.Id, crud:3, show:true}) } 
            onExpand={ ()=>onAction({id:item.Id, crud:2, show:true}) }
            />             
        </div>
    )
}

export {MemberGrid}
