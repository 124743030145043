import axios from 'axios';

const Api = axios.create({

  baseURL: `https://akademy.digitalservices.ma`, 
  //baseURL: `https://localhost:44371`,  

  
  headers : {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('Akademy'))?.token}`,       
    'Content-Type': 'application/json',
    //'Accept': 'application/json',
    //'Access-Control-Allow-Origin': '*',
    //'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept'
  },

  domain: 'assirajolmounir'
})


async function Fetch(request, item=null, crud=2, Load=null, Message=null, Items=null) {
  //alert(Api.defaults.baseURL+" "+request+" "+crud+" "+Api.defaults.headers.Authorization)

  const methods="1234"
  if(!methods.includes(crud)) 
    throw new Error(`Fetch method ${crud} invalid ?`) 

  const method = (crud===1 ? 'POST' : (crud===3 ? 'PUT' : (crud === 4 ? 'DELETE' : 'GET')))

  //console.log('Fetch', request, crud, method, headers, item)  //alert(request+' '+crud+' '+method)
  if(!request.startsWith('https'))    
      request = Api.defaults.baseURL+"/"+request

  let response = await fetch(request,{
      method: method,
      headers: Api.defaults.headers,
      body: item && JSON.stringify(item)
  })

  if(response.ok){
          
    if(crud!==2) {
      //console.log(response)
      Message && Message(`Api.Fetch ${response.status} !`)
      Load && Load(false)
    }
    else {
      let data = await response.json()
      //console.log('Fetch ', request, data)
      Items && Items(data)  // 2 hours :) 
      Load && Load(true)
    }
  }
  else {
    Message && Message(`Api.Fetch ${response.status} ?`)
    //console.error(response)
  }
}

async function Post(controller, item, Message) {

  let response = await Api.post(controller, item)
  //console.log('Api.Post', controller, item, response);  alert(response.data) 
  if(response.status===201 || response.status===302){   
      Message(response.data) 
      return response
  }
  else {
    let message = `Api.Post ${response.data} ?`
    Message(message)    //console.error(response)
    throw new Error(message)
  }
}

const Get = (request, Items, Load=null, Message=null) => {
  //alert(request)
  Items([])

  Api.get(request)
  .then(response => {
      //console.log('Api.Get', request, response.data)
      Items(response.data)
      Load && Load(true)
      Message && Message(response)
  })
  .catch(error => {
      Message && Message(error)
      //console.error(error)
  })
}

const Cookie = (key) => {
  let name = (key + "=")
  let cookies = decodeURIComponent(document.cookie)
  //console.log('cookies',key,cookies)

  let ca = cookies.split(';')
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
}



export default Api
export {Fetch, Get, Post, Cookie}
