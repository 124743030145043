import React,{useState} from 'react'
import {Button, Form, Col} from 'react-bootstrap'
import './Role.css'
import {MemberControl} from '../Member/MemberControl'
import {Post} from '../Api'

const RecommendMember = ({role, entityId, onHide, Message, entity="Team"}) => {
    const [loading, Loading] = useState(false)
    const [member, Member] = useState(2)

    const onSave = (event) => {
        event.preventDefault();
        Loading(true)

        //  MeR 230502
        let item = JSON.stringify({
            Entity:{
                Id:entityId,
                Name:entity
            },                           
            Role:role, // Api will take care of it !
            MemberId:member
        })

        Post(`Role`, item, Message)
        .then(response => {
            Loading(false)
            onHide(member)
        })
    }

    const onChange = (e) =>{
        e.preventDefault();
        Member(e.target.value)
    }
    
    return(
        <div className='Option'>
            <Form>
                <div className="row">
                <div className="column left">
                    <Button onClick={onSave} variant="success" type="submit" style={{width:"auto", fontSize:"1.1rem", marginTop:"2rem"}}>
                        <i className="fa fa-save"></i>   
                    </Button>
                </div>
                <div className="column right">
                    <MemberControl crud={3} role={role} label={role} onChange={onChange} Message={Message}/> 
                </div>
                </div>
            </Form>
        </div>
        )
}

export default RecommendMember