import React, { useState, useEffect } from 'react'
import './Managing.css'
import {Container,Row,Col} from 'react-bootstrap'
import SplitPane from 'react-split-pane'
import {Performing} from '../Performing/Performing'
import {Teams} from '../Team/Teams'
import {Notes} from '../Note/Notes'
import { Timers, Focused } from '../Timer/Timers'
import { Members } from '../Member/Members'
import { MemberGrid } from '../Member/MemberGrid'

export const Persons = ({global, layout, search, onSearch}) => {
        const activity=5
        const [team, Team] = useState(0)
        const [note, Note] = useState(0)
        const [reported, Reported] = useState(0)
              
        Focused(1002, activity,  Reported )

        const onNote = (id) => {
            Note(id)
        }

        const onMember = (id) => {
                alert(id)
        }

        useEffect(() => {
                //alert('useEffect '+itemId)
        }, [team])   

return (

<>
                
{ global.connected() ?
<>
{ layout ?
<SplitPane split="vertical" defaultSize={"30%"} style={{height:'calc((100vh - 4.5rem) - 20px)'}}>

        <SplitPane split="horizontal" defaultSize={"70%"}>
                <Notes activity={activity} search={search} element={1002} onNote={onNote} />
                <Timers element={1002} activity={5} reported={reported}/>
        </SplitPane>
        
        <MemberGrid global={global} onMember={onMember} />

</SplitPane>
:
<SplitPane split="horizontal" defaultSize={"50%"} style={{position:"relative"}}>
        <MemberGrid global={global} onMember={onMember} />

        <SplitPane split="horizontal" defaultSize={"70%"}>
                <Notes activity={activity} search={search} element={1002} onNote={onNote} />
                <Timers element={1002} activity={5} reported={reported}/>
        </SplitPane>    
</SplitPane>

}
</>

: <h5>Not Connected</h5> }

</>
        )
}

