import React, {useState,useEffect} from 'react'
import {Form} from 'react-bootstrap'
import Api, {Get, Fetch} from '../Api'
import {ToNotify} from './ViewControl'


const InOption = ({member=0, entity, id, label, onChange=null, readOnly=false, Selected=null,valid=true}) => {
    const [message, Message] = useState('')
    const [loaded, Load] = useState(false)
    const [items, Items]= useState([])

    useEffect(() => {
        if(loaded) {
            if(Selected) {
                const item = items.filter(i=>i.Id===parseInt(id))[0]  
                Selected && item && Selected(item)
            }
        }
        else {
            Get(`Option/${Api.defaults.domain}/${entity}`, Items, Load)
        }
     }, [loaded])       

    const onSelect=(e) =>{
        if(e?.target) {
            if(Selected) {
                const item = items.filter(i=>i.Id===parseInt(e.target.value))[0]  
                //console.log(e.target.value, item, items)
                Selected && Selected(item)    
            }

            onChange && onChange(e)
        
            if(member>0 && id!==e.target.value) {
                //alert(`onSelect ${member},${entity} : ${e.target.name} value=${e.target.value} id=${id}`)
                Fetch('Option',{MemberId:member, OptionId:e.target.value},3,null,Message)                
            }
        }
    }
 
    return (
        loaded ? 
        <Form.Group className='form-row' style={{margin:'1rem 0',fontSize:'1.2rem', padding:'0 1rem', color:'black'}}>

            <Form.Label style={{margin:0}} className='col-sm-12' align='right'>{label ?? entity}
            </Form.Label>

            <Form.Control className='col-sm-12 text-right' as="select" name={entity+"Id"} onChange={onSelect}
                style={{border: valid?'none':'3px solid red', fontSize:'1.2rem', color:'rgb(0,20,200)'}} 
                defaultValue={id}
                readOnly={readOnly} disabled={readOnly} >
                {
                    items.map(item => 
                    <option style={{paddingRight:'1rem'}} key={item.Id} value={item.Id} selected={id===item.Id}>{item.Value}</option>
                    )
                }
            </Form.Control> 
        
            <ToNotify 
                message={message}
                onClose={()=>{Message('')}}
            />  
        </Form.Group> 
        
        : <div>{entity}s is loading...</div> 

        
    )
}

const InText = ({name,value,label,type,size,onChange=null,valid=true,readOnly=false,onBlur=null}) => {

    return (
        <Form.Group controlId={`InText-${name}`} className='form-row conter' style={{margin:'0.5rem 0', padding:'0 1rem'}}>
            
            <Form.Control
            className={ (name==='code') ? 'col-sm-9 text-center' : (name==='Phone')?'col-sm-12 text-center':'col-sm-12 text-right' }
            type={type ?? 'text'}
            name={name}                
            placeholder={label ?? name}
            defaultValue={value}
            style={{border: valid?'none':'3px solid red', fontSize:size??'1.2rem', color:'rgb(0,20,200)', margin:'0.6rem 0'}}               
            readOnly={readOnly}
            onChange={onChange}
            onBlur={onBlur}
            //  required
            />
            {/*<Form.Label className='col-sm-2' align='right'>:{label ?? name}</Form.Label>*/}
        </Form.Group>        
    )
}

const InDetail = ({name,value,label,readOnly,size,onChange}) => {

    return (
        <Form.Group controlId={`InDetail-${name}`} className='form-row conter' style={{margin:'0.5rem 0', padding:'0 1rem'}}>
            <textarea className="form-control text-right" rows="2" 
                name={name} 
                type="text"
                style={{fontSize:size??'1.2rem', marginTop:'1rem'}}
                defaultValue={value ??''} 
                align='right'
                placeholder={label ?? name}
                readOnly={readOnly??false}  
                onChange={onChange}          
            />
        </Form.Group>
    )
}

/*
In React, components need to be capitalized !
*/
const InCheck = (name, value=false, mode=0, label) => {
    const [checked, setChecked] = React.useState(value)
    return (
        <div className="form-group form-row">
      <label className="control-label col-sm-2" style={{width:"auto", textAlign:"right"}}>
          {label ?? name}: </label>
          <div className="col-sm-1">
            <input type="checkbox" name={name}
            defaultChecked={checked}
            onChange={() => setChecked(!checked)}
            readOnly={(mode??0)===0}
            className="form-control" 
            />        
        </div>
      </div>     
    )
}

export {InOption, InText, InDetail, InCheck}
