import React, { useState, useEffect } from 'react'
import './Managing.css'
import SplitPane from 'react-split-pane'

import {Planning} from '../Planning/Planning'
import { Classes } from '../Classe/Classes'

export const Subjects = ({global, role, search}) => {
        const [classe, Classe] = useState(0)

        const onClasse = (id) => {
                Classe(id)
        }


return (

<>
                
{ role>2 ?
        <SplitPane split="horizontal" defaultSize={"50%"} style={{position:"relative"}}>
                <Classes global={global} search={search} onClasse={onClasse} />
                <Planning global={global} role={role} classe={classe} search={search} />                   
        </SplitPane>

: <h5>Not Connected</h5> }

</>
        )
} 


/*
 style={{ width:"max-content", height:"max-content", margin:0, padding:0}}
*/