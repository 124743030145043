import React, {useState, useRef} from 'react'
import {Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'

import './App.css'
import Home from './components/Home'
import {SignUp} from './components/Sign/SignUp'
import {Members} from './components/Member/Members'
import {Registering} from './components/Member/Registering'
import {Courses} from './components/Course/Courses'
import {Classes} from './components/Classe/Classes'
import {Lessons} from './components/Lesson/Lessons'
import {Teams} from './components/Team/Teams'
import {Booking} from './components/Booking/Booking'

import {Managing} from './components/Managing/Managing'
import {Planning} from './components/Planning/Planning'
import {Performing} from './components/Performing/Performing'

import Header from './components/Header'
import Footer from './components/Footer'
import Global from './components/Global'
import SignIn from './components/Sign/SignIn'

import {Settings} from './components/Setting/Settings'
import {Persons} from './components/Managing/Persons'
import {Subjects} from './components/Managing/Subjects'
import Memorizing from './components/Memorizing/Memorizing'

function App() {
  const global = new Global()

  const route = useRef('/') 
  const [search, Search]  = useState("")
  const [course, Course]  = useState(1)
  const [etat, Etat]  = useState("")
  const [role, Role]  = useState(0)

  const layout = window.matchMedia('(orientation:landscape)').matches

  return (
<BrowserRouter>
    <Header global={global} onSearch={Search} onRole={Role} role={role} route={route} layout={layout} />

    <Switch>
      <Route path='/' component={() => <Home search={search} connected={global.connected()} appLayout={layout}  role={role} />} exact />

      <Route path='/signup' component={() => <SignUp global={global} route={route} />} />
      <Route path='/signin' component={() => <SignIn apiUrl={global.apiUrl} show={true} />} />

      <Route path='/member' component={() => <Members global={global} search={search} />} />
      <Route path='/registering' component={() => <Registering global={global} />} />
      <Route path='/team' component={() => <Teams global={global} search={search} role={role} Etat={Etat} />} />

      <Route path='/classe' component={() => <Classes global={global} id={2} />} />        
      <Route path='/lesson' component={() => <Lessons global={global} />} />

      <Route path='/managing' component={() => <Managing global={global} layout={layout} search={search}  />} />
      <Route path='/planning' component={() => <Planning global={global} role={role} search={search} />} />
      <Route path='/booking' component={() => <Booking global={global} />} />
      <Route path='/performing' component={() => <Performing global={global}  />} />

      <Route path='/setting' component={() => <Settings global={global} search={search} role={role} Etat={Etat} />} />
      <Route path='/person' component={() => <Persons global={global} layout={layout} search={search} />} />
      <Route path='/subject' component={() => <Subjects global={global} role={role} search={search} />} />

      <Route path='/course' component={() => <Courses onCourse={Course} global={global} role={role} />} /> 
      <Route path='/memorizing' component={() => <Memorizing search={search} onEtat={Etat} role={role} course={course} layout={layout} />} />

    </Switch>

    <Footer etat={etat} layout={layout}/>

    <Redirect to={route.current} />

  </BrowserRouter>

  )
}

export default App;

