import React,{Component} from 'react';
import {Modal,Button,Row,Col,Form} from 'react-bootstrap';
import {ToNotify} from '../Shared/ViewControl'
import Api from '../Api'

export class ActivityEditModal extends Component {
    constructor(props){
        super(props)

        this.state = {states:[], snackbaropen:false, snackbarmsg:'', Activity:null, loading:false}
        this.handleSubmit = this.handleSubmit.bind(this)

        this.mode = this.props.mode ?? 0
    }

    componentDidMount(){

        if(this.props.show && this.props.itemId>0) {
        this.setState({loading:true})
        let request = `${this.props.global.apiUrl}/Activity/2-${this.props.itemId}`
        //alert(request)
        
        fetch(request, {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${this.props.global.store.token}`
              })
        })
        .then(response => response.json())
        .then(data => {
            this.setState({Activity:data, loading:false})
        })
        .catch(function (error) {
            console.log('get Activity',request,error)
        })
        }
    }

    snackbarClose = (event) => {
        this.setState({snackbaropen:false});
    }

    handleSubmit(event){
        event.preventDefault();
        //alert(event.target.Role.value);
        fetch(`${this.props.global.apiUrl}/Member`,{
method:(this.mode===2)?'POST':'PUT',
headers:{
    'Accept':'application/json',
    'Content-Type':'application/json',
    Authorization: `Bearer ${this.props.global.store.token}` 
},
body:JSON.stringify({
Id:(this.mode===2)?0:this.state.Member.Id,
Domain:Api.defaults.domain,
RoleId:event.target.RoleId.value,
Name:event.target.Name.value,
Description:event.target.Description.value
})
})
.then(response => response.json())
        .then(
            (result)=>{
                this.setState({snackbaropen:true, snackbarmsg:result});

            },
            (error)=>{
                this.setState({snackbaropen:true, snackbarmsg:error});
            }
    )

}
    
    render() {
        return(
            <div className="container">
<ToNotify 
message={this.state.snackbarmsg}
onClose={this.snackbarClose}
/>
            <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {
                  this.mode===1 ?<h3>تحديث النشاط</h3>:<h3>الطالبة</h3>
              }
              ({this.state.Member ? this.state.Member.Id : 0})
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

 <Row>
     <Col sm={8} >
         {
            this.state.loading ? <div>Member is loading...</div> :
         <Form onSubmit={this.handleSubmit}>



{this.mode>0 ?
            <Form.Group>
                 <Button variant="success" type="submit">حفظ</Button>
            </Form.Group>
:null}
         </Form>
        }
     </Col>
 </Row>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-danger" onClick={this.props.onHide}>إلغاء</Button>
            </Modal.Footer>
          </Modal>
          </div>  
        );
    }
}
