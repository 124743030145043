import React from 'react'
import './Service.css'
import {NavLink} from 'react-router-dom'; 
import Config from '../Config/ar'
import '../Shared/media.css'


const Services = ({search='', layout=false}) => {

    return (
        <div className='Services'>

<Service name="السراج المنير" 
  icon={Config.logo} 
  info="المسجلون" 
  to="/registering"
  layout={layout}
/>

<Service name="Support Akademy" 
  icon="./images/telegram.png" 
  info="المجموعة" 
  to="https://t.me/+_pHbmLJFz0tiMzE0"
  layout={layout}
/>

<Service name="إحفظ" 
  icon="./images/hifd.png" 
  info="إحفظ" 
  plan="PRO" 
  to="/memorizing"
  start='/memorizing'
  layout={layout}
/>

<Service name="الأعضاء" 
  icon={Config.logo} 
  info="الأعضاء" 
  to="/team"
  layout={layout}
/>

<Service name="الدروس" 
  icon={Config.logo} 
  info="الدروس" 
  to="/course"
  layout={layout}
/>

</div>
    )

}

const Service = ({name, icon, info='', plan='', start='', to, style, layout=false}) => {

    //start && alert(start)

    return (
        <div className={style?'Action':'Service'} style={style}>
            
            {   to.startsWith('http') ?
            <a target="_blank" rel="noopener noreferrer" href={to}>
                <img src={icon} alt={name+": "+info} />
            </a>
            :
            <NavLink to={to} >
                <img src={icon} alt={name+": "+info} />
            </NavLink>
            }

            <div className='Detail'>

                {to.startsWith('http') ?
                <div className='Name'>{name}</div>
                :
                <NavLink to={to}>
                    <div className='Name'>{name}</div>
                </NavLink>
                }
                
                <div className="Info">{info}</div>
                
                {plan.length>0 ?
                <div className="Plan">{plan}</div>
                : null }

                {style && start?.length>0 ?
                <div style={{width:'100%', position:'relative'}}>
                <a href={to} target="_blank" rel="noreferrer" >
                    <button className='Button'
                        style={to.includes('telegram') ? {backgroundColor:'rgb(40,170,240)'} : null }
                    >
                    {start}
                    </button>
                </a>
                </div>
                : null }
            </div>
        </div>
    )
}

export {Service, Services}

/*
<button title="button title" class="action primary tocart" onclick=" window.open('https://akademy.ma', '_blank'); return false;">Akademy</button>
by the use of return false we can remove/cancel the default behavior of the button like submit.
*/

/**
 *                     <Button onClick={() => { 
                        window.open({to}, '_blank')
                        return false
                    }}>{start}</Button>

 */