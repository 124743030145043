import {Component} from 'react';
import Api from './Api'

export default class Global extends Component{

    constructor(props){
        super(props)
        this.state = {items:[], loading:false}

        this.store=null
        this.apiUrl=Api.defaults.baseURL
        //alert(this.apiUrl)

        this.headers = {            
            'Accept':'application/json',
            'Content-Type':'application/json'
        }
        
        this.parseStorage()
        this.search=""
        this.message=""

        //console.log('constructor apiUrl',this.apiUrl)
    }

    static search=""
    static message=""

    connected() {
        if(this.store && this.store?.connected)
            return true
        
        this.message="Invalid session?"
        return false
    }

    parseStorage() {
        //alert("parseStorage")
        this.store = JSON.parse(localStorage.getItem('Akademy'))
        //console.log('parseStorage',this.store)
        if(this.connected()) {
            this.headers = {
                Authorization: `Bearer ${this.store.token}`,
                ...this.headers                
            }
            //console.log('headers',this.headers)
        }
    }
    
    async GET(relativeUrl) {

        if(this.connected()) {
            
            this.state ?? this.setState({loading:true})
            let request=this.apiUrl+'/'+relativeUrl
            //console.log('Global.GET',request)
            
            let response = await fetch(request, {
                method: "GET",
                headers: this.headers
            })
            if(response.ok){
                let data = await response.json()
                this.state ?? this.setState({items:data, loading:false})
                return data
            }
            else {
                throw new Error(`GET : ${response.status} ?`)
            }
        }
    }

    async GetItem (controller, id) {

        this.state ?? this.setState({loading:true})

        let request = `${this.apiUrl}/${controller}/${id}`
        
        let response = await fetch(request, {
            method: "GET",
            headers: this.headers
        })
        if(response.ok){
            let data = await response.json()
            this.state ?? this.setState({loading:false})
            return data[0]
        }
        else {
            throw new Error(`GetItem : ${response.status} ?`)
        }
    }


    async DELETE(controller, id)
    {
        let request = `${this.apiUrl}/${controller}/${id}`

        if(window.confirm('Are you sure to delete this item?')){
            let response = await fetch(request, {
                method:'DELETE',
                headers: this.headers,
                body:JSON.stringify({
                    account:this.store.account
                })
            })
            //console.log('response', response)
            if(!response.ok)
                throw new Error(`DELETE : ${response.status} ?`)
        }
    }

    Delay = ms => new Promise(res => setTimeout(res, ms));

    toString(datetime) {
        const meDate = (datetime) ? new Date(datetime) : new Date() ;
        return meDate.toISOString().split('T')[0];
    }

    toDuration(from, to){
        const fromDate = (from) ? new Date(from) : new Date() ;
        const toDate = (to) ? new Date(to) : new Date() ;
        const date = new Date(toDate-fromDate);
        var hh = date.getUTCHours();
        var mm = date.getUTCMinutes();
        return (("0"+hh).slice(-2)+":"+("0"+mm).slice(-2));
    }

    addDays(days, date=null) {
        var result = date ? new Date(date) : new Date();
        result.setDate(result.getDate() + days);
        return result;
    }

}

//export default Global